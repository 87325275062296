import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserService } from '../../Services/User.service'

const sharedStore = namespace('Shared')

@Component
export default class ConfirmResetPasswordPage extends Vue {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public isPasswordShow = false
  public password = ''
  public logo = require('../../../assets/logo-login-screen.png')

  public async onReset(): Promise<void> {
    try {
      await UserService.selfPasswordUpdate(this.password, this.$route.query.code.toString())

      this.setNotification({ text: 'Password reset successfully, redirect...', timeout: 5000 })
    } catch {} finally {
      setTimeout(() => {
        this.isLoading = false
        this.$router.push('/login')
      }, 3000)
    }
  }

  public onInputIconClick(): void {
    this.isPasswordShow = !this.isPasswordShow
  }

  get inputType(): 'text' | 'password' {
    return this.isPasswordShow ? 'text' : 'password'
  }

  get inputIcon(): string {
    return this.isPasswordShow ? 'fa fa-eye' : 'fa fa-eye-slash'
  }
}
